import React, { Fragment } from "react";
import HeaderTopbar from "../../components/HeaderTopbar/HeaderTopbar";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import About from "../../components/about/about";
import AboutCompany from "../../components/about/about_company";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import BlogSection from "../../components/BlogSection/BlogSection";
import PricingSection from "../../components/PricingSection/PricingSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/logo-3.svg";
// import AboutPageTitleBg from "../../images/about/about_title_bg.jpg";
import AboutPageTitleBg from "../../images/about/about_bg_2.jpg";

const AboutPage = () => {
  return (
    <Fragment>
      {/* <HeaderTopbar /> */}
      <Navbar hclass={"wpo-site-header wpo-site-header-s3"} Logo={Logo} />
      <PageTitle
        pageTitle={"About Depthtech"}
        pagesub={"About"}
        backgroundUrl={AboutPageTitleBg}
        backgroundPos={"top"}
      />
      {/* <About hclass={'wpo-about-section section-padding'} /> */}
      <AboutCompany hclass={"wpo-about-section section-padding"} />
      {/* <ServiceSection hclass={"wpo-service-section section-padding"} /> */}
      {/* <PricingSection pClass={"wpo-pricing-section section-padding pt-0"} /> */}
      {/* <BlogSection tClass={'wpo-blog-section section-padding'} /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
