import React from "react";
import WhyUs from "../../api/WhyUs";
import { Link } from "react-router-dom";

const ServiceSection3 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <section className={"" + props.hclass}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-12">
            <div
              className="wpo-section-title-s2"
              style={{
                marginTop: "80px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h3>
                Specialized wet chemical process with more than 20 years of R&D,
                leading technical service, and manufacuring.
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-12">
            <p>
              At Depthtech, we are driven by a commitment to scientific
              expertise, innovation, quality and customer success. With over 24
              years of industry experience, we ensure that every solution we
              provide meets the client’s expectation for the highest standards
              of PCB fabrication.
            </p>
            <p>
              Our focus on continuous innovation means that we stay ahead of
              industry trends, offering advanced chemical solutions that enhance
              your production efficiency and product reliability. We are also
              deeply committed to sustainability, providing environmentally
              responsible products that don’t compromise on performance.
            </p>
            <p>
              When you choose Depthtech, you're choosing a partner who
              understands your needs, values your success, and works tirelessly
              to help you achieve superior results in every project.
            </p>
          </div>
        </div>
        <div className="row">
          {WhyUs.slice(0, 4).map((service, index) => (
            <div className="col-lg-3 col-6" key={index}>
              <div className="service-card">
                <div className="content">
                  <div className="icon">
                    {/* <CustomIcon iconName={service.icon} /> */}
                    <img src={service.icon} alt="" />
                  </div>
                  <div className="text">
                    <h2>{service.title}</h2>
                    {/* <p>{service.description}</p> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceSection3;
