import React, { Fragment } from "react";
import HeaderTopbar from "../../components/HeaderTopbar/HeaderTopbar";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Contactpage from "../../components/Contactpage/Contactpage";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";

import Logo from "../../images/logo-3.svg";
// import ContactPageTitleBg from '../../images/contact_page_title.jpg';
import ContactPageTitleBg from "../../images/contact/contact_bg.jpg";
const ContactPage = () => {
  return (
    <Fragment>
      {/* <HeaderTopbar /> */}
      <Navbar hclass={"wpo-site-header wpo-site-header-s3"} Logo={Logo} />
      <PageTitle
        pageTitle={"Contact Us"}
        pagesub={"Contact"}
        backgroundUrl={ContactPageTitleBg}
        backgroundPos="bottom"
      />
      <Contactpage />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ContactPage;
