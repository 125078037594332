import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../HomePage/HomePage";
import HomePage2 from "../HomePage2/HomePage2";
import HomePage3 from "../HomePage3/HomePage3";
import HomePage4 from "../HomePage4/HomePage4";
import HomePage5 from "../HomePage5/HomePage5";
import HomePage6 from "../HomePage6/HomePage6";
import AboutPage from "../AboutPage/AboutPage";
import ProjectPage from "../ProjectPage/ProjectPage";
import ProjectSingle from "../ProjectSingle/ProjectSingle";
import ServicePages from "../ServicePage/ServicePage";
import ServicePages2 from "../ServicePage2/ServicePage2";
import ServicePages3 from "../ServicePage3/ServicePage3";
import ServiceSinglePage from "../ServiceSinglePage/ServiceSinglePage";
import FeaturedProduct from "../ServiceSinglePage/FeaturedProduct";
import DetailsPage from "../ProductDetails/DetailsPage";
import TeamPage from "../TeamPage/TeamPage";
import TeamSinglePage from "../TeamSinglePage/TeamSinglePage";
import ShopPage from "../ShopPage";
import ProductSinglePage from "../ProductSinglePage";
import CartPage from "../CartPage";
import CheckoutPage from "../CheckoutPage";
import OrderRecived from "../OrderRecived";
import PricingPage from "../PricingPage/PricingPage";
import BlogPage from "../BlogPage/BlogPage";
import BlogPageLeft from "../BlogPageLeft/BlogPageLeft";
import BlogPageFullwidth from "../BlogPageFullwidth/BlogPageFullwidth";
import BlogDetails from "../BlogDetails/BlogDetails";
import BlogDetailsLeftSiide from "../BlogDetailsLeftSiide/BlogDetailsLeftSiide";
import BlogDetailsFull from "../BlogDetailsFull/BlogDetailsFull";
import ContactPage from "../ContactPage/ContactPage";
import ErrorPage from "../ErrorPage/ErrorPage";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="404" element={<ErrorPage />} />
          <Route path="home" element={<Homepage />} />
          {/* <Route path="home-2" element={<HomePage2 />} />
          <Route path="home-3" element={<HomePage3 />} />
          <Route path="home-4" element={<HomePage4 />} />
          <Route path="home-5" element={<HomePage5 />} />
          <Route path="home-6" element={<HomePage6 />} /> */}
          <Route path="about" element={<AboutPage />} />
          {/* <Route path="service" element={<ServicePages />} />
          <Route path="service-s2" element={<ServicePages2 />} />
          <Route path="service-s3" element={<ServicePages3 />} /> */}
          <Route path="product-detail" element={<DetailsPage />} />
          <Route path="featured-product" element={<FeaturedProduct />} />
          <Route path="products/:slug" element={<ServiceSinglePage />} />
          <Route path="project" element={<ProjectPage />} />
          <Route path="project-single/:slug" element={<ProjectSingle />} />
          {/* <Route path="pricing" element={<PricingPage />} />
          <Route path="team" element={<TeamPage />} />
          <Route path="team-single/:slug" element={<TeamSinglePage />} />
          <Route path="shop" element={<ShopPage />} /> */}
          {/* <Route path='product-single/:slug' element={<ProductSinglePage />} /> */}
          {/* <Route path='cart' element={<CartPage />} /> */}
          {/* <Route path='checkout' element={<CheckoutPage />} /> */}
          {/* <Route path='order_received' element={<OrderRecived />} /> */}
          {/* <Route path='blog' element={<BlogPage />} />
          <Route path='blog-left-sidebar' element={<BlogPageLeft />} />
          <Route path='blog-fullwidth' element={<BlogPageFullwidth />} />
          <Route path='blog-single/:slug' element={<BlogDetails />} />
          <Route path='blog-single-left-sidebar/:slug' element={<BlogDetailsLeftSiide />} />
          <Route path='blog-single-fullwidth/:slug' element={<BlogDetailsFull />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
