import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/hero/hero";
import FunFact from "../../components/FunFact/FunFact";
import About from "../../components/about/about";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import HistorySection from "../../components/HistorySection/HistorySection";
import TeamSection from "../../components/TeamSection/TeamSection";
import ProjectSection from "../../components/ProjectSection/ProjectSection";
import Testimonial from "../../components/Testimonial/Testimonial";
import BlogSection from "../../components/BlogSection/BlogSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/dep_logo.png";
import ServiceSection3 from "../../components/ServiceSection3/ServiceSection3";

const HomePage = () => {
  return (
    <Fragment>
      <Navbar hclass={"wpo-site-header"} Logo={Logo} />
      <Hero hclass="static-hero" />
      <ServiceSection hclass={"wpo-service-section section-padding"} />
      <About hclass={"wpo-about-section section-padding"} />
      <ServiceSection3 hclass={"wpo-service-section-s3 section-padding pt-0"} />
      <BlogSection
        tClass={"wpo-blog-section section-padding"}
        title="Featured Download"
      />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
