import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";
import blogs from "../../api/blogs";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const handleDownload = (download, filename) => {
  const link = document.createElement("a");
  link.href = download; // URL to the file
  link.download = filename; // Specify the filename to save as
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // Clean up after download
};

const BlogSection = (props) => {
  return (
    <section className={"" + props.tClass}>
      <div className="container">
        <div className="row justify-content-left">
          <div className="col-12">
            <SectionTitle title={"blog"} subtitle={props.title} />
          </div>
        </div>
        <div className="row">
          {blogs.slice(0.1).map((blog, bkye) => (
            <div className="col-lg-4 col-md-4 col-12" key={bkye}>
              <div className="blog-card">
                <div className="image">
                  <img src={blog.screens} alt="" />
                </div>
                <div className="content">
                  {/* <ul>
                    <li>{blog.create_at}</li>
                    <li>By: {blog.author}</li>
                  </ul> */}
                  <h2
                    style={{ minHeight: "40px", fontSize: "22px" }}
                    className="blog-title"
                  >
                    <Link
                      onClick={() => handleDownload(blog.link, blog.filename)}
                    >
                      {blog.title}
                    </Link>
                  </h2>
                  <p>{blog.description}</p>
                  <Link
                    onClick={() => handleDownload(blog.link, blog.filename)}
                  >
                    Download
                    <i className="ti-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
