// images
import blogImg3 from "../images/blog/download_2.jpg";
import blogImg1 from "../images/blog/article_1.jpg";
import blogImg2 from "../images/blog/article_2.jpg";

import blogSingleImg1 from "../images/blog-details/1.jpg";
import blogSingleImg2 from "../images/blog-details/2.jpg";

import download1 from "../downloads/article_1.pdf";
import download2 from "../downloads/article_2.pdf";
import download3 from "../downloads/brochure1.pdf";

const blogs = [
  {
    id: "1",
    title: "TPCA Journal, paper publication",
    subtitle: "Manik Doe",
    screens: blogImg1,
    link: download1,
    filename: "TPCA Journal Part I.pdf",
    description:
      "Colloid science is the foundational expertise behind DCCT™ that Depthtech has grasped used to develop patented technology to modernize PCB fab.",
    blogSingleImg: blogSingleImg1,
    blClass: "format-standard-image",
    animation: "1200",
  },
  {
    id: "2",
    title: "TPCA Journal, paper publication II",
    screens: blogImg2,
    link: download2,
    filename: "TPCA Journal Part II.pdf",
    description:
      "DCCT™ is the new solution for scum-free development process. Analyzing particle size and zeta potential charts DCCT™ helps monitor microscopic.",
    author: "Linda Johns",
    blogSingleImg: blogSingleImg2,

    blClass: "format-standard-image",
    animation: "1200",
  },
  {
    id: "3",
    title: "PCB West 2024 Content Package",
    screens: blogImg3,
    link: download3,
    filename: "PCB West 2024 Brochure.pdf",
    description:
      "Depthtech joins the world's leading thinkers at PCB West 2024 conference. Download our promotional content to see what we have to offer!",
    author: "Linda Johns",
    blogSingleImg: blogSingleImg2,
    blClass: "format-standard-image",
    animation: "1200",
  },
];
export default blogs;
