import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/dep_logo.png";
import Services from "../../api/Services";
import PartnerSection from "../PartnerSection/PartnerSection";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Footer = (props) => {
  const [email, setEmail] = useState("");

  const handleReset = () => {
    setEmail("");
  };
  return (
    <>
      <footer className="wpo-site-footer">
        <div className="wpo-upper-footer">
          <div className="container">
            <div className="row desktop-ver">
              <div className="col col-lg-9 col-md-12 col-sm-12 col-12">
                <div className="row">
                  <div className="col col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="widget">
                      <div className="widget-title">
                        <h3>Our locations</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="widget">
                      <ul className="location-list">
                        <li>
                          <b>
                            帝科有限公司 <br /> (Global headquarter)
                          </b>
                          <p>
                            215 Lane 31, Lunping 5th Road <br />
                            Guanyin District, Taoyuan, Taiwan.
                            <br />
                            Telephone: (+886) 3-271-1650
                            <br />
                            Fax: (+886) 3-287-2697
                            <br />
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="widget">
                      <ul className="location-list">
                        <li>
                          <b>太倉信科電子化學制劑有限公司 </b>
                          <p>
                            Unit 1701, Lide International Building
                            <br />
                            666 Changjiang South Road, Kunshan Economic
                            Development Zone
                            <br />
                            Kunshan, Jiangsu, China.
                            <br />
                            Telephone: (+86) 512-551-91280
                            <br />
                            Fax: (+86) 512-551-91281
                            <br />
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="widget">
                      <ul className="location-list">
                        <li>
                          <b>松柏貿易有限公司 </b>
                          <p>
                            Unit 2303, Building 26, Dongfang Building
                            <br />
                            Songgang Street, Bao'an District, Shenzhen,
                            Guangdong, China.
                            <br />
                            Telephone: (+86) 755-230-31380
                            <br />
                            Fax: (+86) 755-230-31381
                            <br />
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="widget about-widget">
                  <div className="logo widget-title">
                    <img src={logo} alt="blog" />
                  </div>
                  <p>
                    Leader in Wet Process Chemicals <br /> for PCB Manufacturing
                  </p>
                  <div className="social-widget">
                    <ul>
                      <li>
                        <Link
                          to="javascript:void(0)"
                          onClick={() =>
                            (window.location = "mailto:albert@depthtech.us")
                          }
                        >
                          <i className="ti-email"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={ClickHandler}
                          to="https://www.linkedin.com/company/depthtech/"
                        >
                          <i className="ti-linkedin"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="widget newsletter-widget">
                  <div className="widget-title">
                    <h3>Newsletter</h3>
                  </div>
                  <div className="email-from">
                    <input
                      className="fild"
                      type="text"
                      placeholder="Your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button type="button" onClick={handleReset}>
                      <i className="ti-arrow-top-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <PartnerSection />
    </>
  );
};

export default Footer;
