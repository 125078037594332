import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import { totalPrice } from "../../utils";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";
import { useLocation } from "react-router-dom";

const Header = (props) => {
  const [menuActive, setMenuState] = useState(false);
  const [cartActive, setcartState] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname !== "/") {
        setIsScrolled(true);
      } else {
        if (window.scrollY > 50) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const { carts } = props;

  return (
    <header id="header">
      <div className={"" + props.hclass}>
        <nav className={`navigation ${isScrolled ? "scrolled" : ""}`}>
          <div className={`navbar-header ${isScrolled ? "scrolled" : ""}`}>
            <Link
              onClick={ClickHandler}
              className={`navbar-brand ${isScrolled ? "scrolled" : ""}`}
              to="/"
            >
              <img
                src={props.Logo}
                alt="logo"
                className={`logo ${isScrolled ? "scrolled" : ""}`}
              />
            </Link>
          </div>
          <MobileMenu />
          <div
            className={`navlist ${isScrolled ? "scrolled" : ""}`}
            id="navbar"
          >
            <ul className="nav mb-2 mb-lg-0">
              <li className="menu-item-has-children">
                <Link onClick={ClickHandler} to="#">
                  Products
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link onClick={ClickHandler} to="/featured-product">
                      DCCT™ Colloid Control
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/products/K-Na">
                      K/Na Developer
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/products/vat_cleaner">
                      Tank Cleaners
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/products/defoamers">
                      Foam Controller
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/products/mask-cleaner">
                      Artwork Cleaner
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/products/anti-oxidant">
                      Copper Antioxidant
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link onClick={ClickHandler} to="/about">
                  About
                </Link>
              </li>
              <li>
                <Link onClick={ClickHandler} to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};
const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};

export default connect(mapStateToProps, { removeFromCart })(Header);
