import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// image
// import pimg1 from '../../images/partners/img-1.png'
// import pimg2 from '../../images/partners/img-2.png'
// import pimg3 from '../../images/partners/img-3.png'
// import pimg4 from '../../images/partners/img-4.png'
// import pimg5 from '../../images/partners/img-5.png'
// import pimg6 from '../../images/partners/img-3.png'

import pimg1 from "../../images/partners/partner_1.png";
import pimg2 from "../../images/partners/partner_2.png";
import pimg3 from "../../images/partners/partner_3.png";

const partners = [
  {
    id: "01",
    pimg: pimg2,
  },
  {
    id: "02",
    pimg: pimg1,
    height: "65px",
  },
  {
    id: "03",
    pimg: pimg3,
  },
];

const PartnerSection = () => {
  const settings = {
    dots: false,
    arrows: false,
    loop: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 768px is a common mobile breakpoint
    };

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="wpo-partners-section">
      <h2>partners</h2>
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="partner-grids partners-slider">
              <Slider {...settings}>
                {partners.map((partner, pitem) => (
                  <div className="grid" key={pitem}>
                    <img
                      src={partner.pimg}
                      alt=""
                      style={{ height: isMobile ? "40px" : "80px" }}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerSection;
