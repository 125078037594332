import React from "react";
import Header from "../header/Header";
import Logo from "../../images/dep_logo.png";

export default function Navbar({ hclass, topbarClass }) {
  const [scroll, setScroll] = React.useState(0);

  const handleScroll = () => setScroll(document.documentElement.scrollTop);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const className = scroll > 80 ? "fixed-navbar active" : "fixed-navbar";

  return <Header hclass={hclass} Logo={Logo} topbarClass={topbarClass} />;
}
