import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Services from "../../api/Services";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const ServiceSection = ({ hclass, title = "Our Products" }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className={"" + hclass}>
      <div className="container">
        <div className="wpo-section-title">
          <h3>Our Products</h3>
        </div>
      </div>
      <div className="service-wrap">
        <div className="service-slider">
          <Slider {...settings}>
            {Services.slice(0, 6).map((service, index) => (
              <div className="item" key={index}>
                <div className="image">
                  <img src={service.image} alt="" />
                  <span>{index + 1}</span>
                  <div className="line-one"></div>
                  <div className="line-two"></div>
                </div>
                <div className="content">
                  {/* <div className="icon">
                    <img src={service.icon} alt="" />
                  </div> */}
                  <h2>
                    <Link
                      onClick={ClickHandler}
                      // to={`${serivce.slug?}/products/${service.slug}`}
                      to={`${service.link}`}
                    >
                      {service.title}
                    </Link>
                  </h2>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
