import React, { Fragment, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

import Services from "../../api/Services";
import ServiceSidebar from "./sidebar";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import BlogSection from "../../components/BlogSection/BlogSection";

import Contact from "./contact";
import { Link } from "react-router-dom";
import simg1 from "../../images/service-single/2.jpg";
import simg2 from "../../images/service-single/3.jpg";
import Footer from "../../components/footer/Footer";
import logo from "../../images/logo-3.svg";
import ProductBannerImg from "../../images/banner/banner2.jpg";

import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import Clearpipe1 from "../../images/compare/clearpipe_clean.png";
import Clearpipe2 from "../../images/compare/clearpipe_dirty.png";

import Conveyor1 from "../../images/compare/conveyor_clean.png";
import Conveyor2 from "../../images/compare/conveyor_dirty.png";

import Gears1 from "../../images/compare/gears_clean.jpg";
import Gears2 from "../../images/compare/gears_dirty.jpg";

import Wetlab1 from "../../images/compare/wetlab_clean.png";
import Wetlab2 from "../../images/compare/wetlab_dirty.png";

import thumbBanner1 from "../../images/products/dcct/thumb1.jpg";
import thumbBanner2 from "../../images/products/dcct/thumb2.jpg";
import thumbBanner3 from "../../images/products/dcct/thumb3.jpg";
import thumbBanner4 from "../../images/products/dcct/thumb-banner2.jpg";

const FeaturedProduct = (props) => {
  const serviceDetails = Services.find((item) => item.slug === "dcct");
  const [tab, setTab] = useState("conveyor");

  const onTabClick = (tabName) => {
    setTab(tabName);
  };

  return (
    <Fragment>
      <Navbar Logo={logo} hclass={"wpo-site-header wpo-site-header-s3"} />
      <PageTitle
        pageTitle={serviceDetails.title}
        pagesub={"Service Single"}
        // backgroundUrl={ProductBannerImg}
        backgroundUrl={serviceDetails.simage}
      />
      <div className="wpo-service-single-area section-padding">
        <div className="container">
          {/* <div className="row">
            <div className="wpo-service-single-title">
              <h2>{serviceDetails.title}</h2>
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="wpo-service-single-wrap">
                {/* <div className="wpo-service-single-item">
                  <div className="wpo-service-single-main-img">
                    <img src={serviceDetails.simage} alt="eimg" />
                  </div>
                </div> */}
                <div className="wpo-service-single-item">
                  <div className="wpo-service-single-title">
                    <h3>
                      DCCT™ Colloid Control Technology pursuit of scum-Free
                      Development Process
                    </h3>
                  </div>
                  <p>
                    <b>
                      Minimizing the risk of scum formations in development
                      baths
                    </b>
                  </p>

                  <p>
                    DCCT™ is a proprietary technology that provides technical
                    solution to effectively manage scum formation in the
                    development bath which are generated from developed
                    photoresists.
                  </p>
                  <p>
                    As substrate pattern design specifications grow much
                    stricter with miniaturization, the impact of substrate
                    defects are increasingly intolerable. As a result,
                    minimization of scum formations in development baths are
                    very important.
                  </p>
                  <p>
                    We can express the natural phenomena of developed
                    photoresists in water-based alkali solutions as colloids.
                    With aid of DCCT™, we can see how the development bath's
                    particle size distribution changes to see if developed
                    photoresist particles aggregate or accumulate, hence forming
                    scum as the development process carries out.{" "}
                  </p>

                  <p>
                    By adopting Depthtech's DCCT™ and our tailored made colloid
                    stabilizer (CS), our PCB customers successfully improve
                    their development process through benefits including
                    increased process yield, decreased consumption of developing
                    agents, reduction of water usage, and increased production
                    line throughput. Moreover, the CO₂ emission is decreased
                    which meets ESG spirit.
                  </p>
                </div>

                <div className="wpo-service-single-item-centered">
                  <div className="wpo-service-single-title">
                    <h3>See the difference in action</h3>
                  </div>
                  <p>
                    <b>
                      DCCT™ keeps developer tanks free of macro and microscopic
                      photoresist residue reducing the burden of process control
                      on plant operators so fabs can focus on innovating their
                      designs.
                    </b>
                  </p>
                  <div className="tag-share clearfix">
                    <div className="tag">
                      <ul>
                        {/* <li>
                          <div
                            className={`tag-btn ${
                              tab === "pipe" ? "active" : ""
                            }`}
                            onClick={(e) => onTabClick("pipe")}
                          >
                            Pipes
                          </div>
                        </li> */}
                        <li>
                          <div
                            className={`tag-btn ${
                              tab === "conveyor" ? "active" : ""
                            }`}
                            onClick={(e) => onTabClick("conveyor")}
                          >
                            Conveyor
                          </div>
                        </li>
                        <li>
                          <div
                            className={`tag-btn ${
                              tab === "gear" ? "active" : ""
                            }`}
                            onClick={(e) => onTabClick("gear")}
                          >
                            Gears
                          </div>
                        </li>
                        <li>
                          <div
                            className={`tag-btn ${
                              tab === "lab" ? "active" : ""
                            }`}
                            onClick={(e) => onTabClick("lab")}
                          >
                            Wet Lab
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="compare-wrapper"
                    style={{
                      width: "auto",
                      overflow: "hidden",
                    }}
                  >
                    {tab === "conveyor" && (
                      <ReactCompareSlider
                        itemOne={
                          <ReactCompareSliderImage
                            src={Conveyor1}
                            // srcSet="..."
                            alt="Image one"
                          />
                        }
                        itemTwo={
                          <ReactCompareSliderImage
                            src={Conveyor2}
                            // srcSet="..."
                            alt="Image two"
                          />
                        }
                      />
                    )}

                    {tab === "gear" && (
                      <ReactCompareSlider
                        itemOne={
                          <ReactCompareSliderImage
                            src={Gears1}
                            // srcSet="..."
                            alt="Image one"
                          />
                        }
                        itemTwo={
                          <ReactCompareSliderImage
                            src={Gears2}
                            // srcSet="..."
                            alt="Image two"
                          />
                        }
                      />
                    )}

                    {tab === "lab" && (
                      <ReactCompareSlider
                        itemOne={
                          <ReactCompareSliderImage
                            src={Wetlab1}
                            // srcSet="..."
                            alt="Image one"
                          />
                        }
                        itemTwo={
                          <ReactCompareSliderImage
                            src={Wetlab2}
                            // srcSet="..."
                            alt="Image two"
                          />
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="wpo-service-single-item">
                  <div className="wpo-service-single-title">
                    <h3>Colloid Stabilizer (CS series)</h3>
                  </div>
                  <p>
                    With aid of DCCT™, CS series are tailored designed
                    formulations which can effectively "control" developed
                    photoresist comprised colloids hence inhibit their
                    aggregation and inhibit scum formation well.
                  </p>
                </div>

                <div className="wpo-service-single-item">
                  <div className="wpo-service-single-title">
                    <h3>Development Enhancing Stabilizer (ES series)</h3>
                  </div>
                  <p>
                    ES series can effectively eliminate the foams generated from
                    developed photoresist's compositions during mechanical
                    pumping in development tanks. ES series is silicone free and
                    will not induce potential defects on substrate surface.
                  </p>
                </div>

                {/* <div className="wpo-service-single-item list-widget">
                  <ul>
                    <li>
                      use special polymers to encapsulate photoresist particles
                      like aircushions in bubble soccer or the amphiphilic
                      molecules in soaps to increase steric stability;
                    </li>
                    <li>
                      design the charge signature over photoresist partilcles
                      like arranging bipolar magnets to increase electrostatic
                      stability.
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            {/* <ServiceSidebar /> */}
          </div>
        </div>
      </div>
      <BlogSection
        tClass={"wpo-blog-section section-padding"}
        title="Publications"
      />
      <div className="wpo-service-single-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="wpo-service-single-wrap">
                <div className="wpo-service-single-item list-widget">
                  <div className="wpo-service-single-title">
                    <h3>Next Generation PCB Fabs care about DCCT™</h3>
                  </div>
                  <p>
                    DCCT™ has helped transform PCB fabs improve yield rate and
                    throughput by offering the following direct benefits:
                  </p>
                  <ul>
                    <li>Improved Yield on the Toughest Designs</li>
                    <li>Reduced Water Usage</li>
                    <li>Reduced Maintenance Cycles</li>
                    <li>Access to Never-before-seen Data</li>
                    <li>Improved Standards for Process Control</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <ServiceSidebar /> */}
          </div>

          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="wpo-service-single-title">
                <h3>Exclusively Depthtech</h3>
                <p>
                  DCCT™ is a proprietary technology invented by Depthtech. This
                  novel technology including the utility of colloid-grade
                  instrumentation for process monitoring and design of specific
                  DCCT™ formulae is exclusively Depthtech’s expertise and
                  Depthtech leads its competitors by serveral years in
                  experience and capability. We are the world’s only company
                  offering a residue-free photoresist development solution.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 hover-card"
              style={{
                background: `url(${thumbBanner1}) no-repeat center top / cover`,
              }}
            >
              <p className="card-title">Colloid Stabilizer</p>
              <div className="card-overlay">
                <div className="card-subtitle">
                  Aqueous solution containing compounds responsible for steric
                  and electrostatic stabilization of photoresist residue
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hover-card"
              style={{
                background: `url(${thumbBanner2}) no-repeat center bottom / cover`,
              }}
            >
              <p className="card-title">Enhancing Agent</p>
              <div className="card-overlay">
                <div className="card-subtitle">
                  Aqueous solution containing compounds responsbile for
                  enhancing developer efficacy including antifoam
                  characteristics
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hover-card"
              style={{
                background: `url(${thumbBanner3}) no-repeat center top / cover`,
              }}
            >
              <p className="card-title">Custom Formulae</p>
              <div className="card-overlay">
                <div className="card-subtitle">
                  Design service optimizing DCCT™ formulae according to your
                  production line’s operational parameters
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hover-card"
              style={{
                background: `url(${thumbBanner1}) no-repeat center top / cover`,
              }}
            >
              <p className="card-title">Particle Size Monitoring</p>
              <div className="card-overlay">
                <div className="card-subtitle">
                  Ongoing service offering access to real-time data and expert
                  analysis for improved standards for process control
                </div>
              </div>
            </div>
          </div>

          <div className="row accordion-wrapper">
            <div className="wpo-service-single-title">
              <h3 style={{ marginTop: "50px" }}>FAQs</h3>
            </div>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Does DCCT™ itself cause problems in production?
                </Accordion.Header>
                <Accordion.Body>
                  No, DCCT™ is safe to use in the developer bath. It does not
                  increase pH and does not adhere to the prepreg, as both
                  demonstrated in rigourous in-lab tests and from the flawless
                  track record of happy customers in the past 24 months since
                  the initial launch of the product in Taiwan. In the past 12
                  months, we have increased our customer footprint by 3x and
                  extended our product sales into the Chinese market.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Does DCCT™ affect exsiting waste water treatement procedures?
                </Accordion.Header>
                <Accordion.Body>
                  No, DCCT™ does not impact the pH of the developer bath, nor
                  does it change the way photoresist polymers coalesces in the
                  presence of acid. In fact, the coalecing behavior of
                  photoresist polymers is a validation on the fundamental
                  colloidal nature of photoresist residue in developer baths.
                  DCCT™, under normal developer operating conditions serves
                  exactly the opposite role as acid and stabilizes the colloid
                  mixture.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Does DCCT™ work for solder mask development processes?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, DCCT™ is designed to work with all development processes
                  relying on UV cured photoresist technologies. Depending on
                  your developer bath, our fullservice solution can help you
                  analyze your bath chemistry and design the optimal stabilizing
                  agent for your production lines. The fullservice solution can
                  even go as far as help you monitor your developer bath’s
                  chemistry and alert you on the optimal time to schedule
                  maintenance cycles and if there is an anomaly in the chemistry
                  for forensics or yield forecasting.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Can I buy DCCT™ in the USA? How about Europe?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, currently we have the ability to ship our products direct
                  from our factories and warehouses in Taiwan and China to all
                  major cities in the world. We are also in conversation with
                  global partners to begin manufacturing regionally in Thailand,
                  North America, and Europe. Get in touch with our
                  representatives for more information.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      <ServiceSection
        hclass={"wpo-service-section section-padding"}
        title="Other Products"
      />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default FeaturedProduct;
