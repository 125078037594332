// images
import img1 from "../images/service/service_1.jpg";
import img2 from "../images/service/service_2.jpg";
import img3 from "../images/service/service_3.jpg";
import img4 from "../images/service/service_4.jpg";

import product1 from "../images/service/product_1.jpg";
import product2 from "../images/service/product_2.jpg";
import product3 from "../images/service/product_3.jpg";
import product4 from "../images/service/product_4.webp";
import product5 from "../images/service/product_5.jpg";
import product6 from "../images/service/product_6.jpg";

// Vat effects
import vatEffect1 from "../images/products/vat_effect_1.jpg";
import vatEffect2 from "../images/products/vat_effect_2.jpg";
import vatEffect3 from "../images/products/vat_surface_1.png";
import vatEffect4 from "../images/products/vat_surface_2.png";

/* Single image */
import simg1 from "../images/products/mask/test_1.jpg";
import simg2 from "../images/products/mask/test_2.jpg";
import simg3 from "../images/products/mask/test_3.jpg";

/* icon image */
import icon1 from "../images/service/icon-1.svg";
import icon2 from "../images/service/icon-2.svg";
import icon3 from "../images/service/icon-3.svg";
import icon4 from "../images/service/icon-4.svg";

import banner1 from "../images/products/kna.jpg";
import banner2 from "../images/products/mask/mask_banner.jpg";
import banner3 from "../images/products/antioxidant/bluebin2.jpg";

const Services = [
  {
    id: 1,
    image: product1,
    icon: icon1,
    simage: product1,
    banner: banner1,
    gradient: "rgba(103, 58, 183, 0.3)",
    title: "DCCT™ Colloid Control Technology",
    description:
      "DCCT™ is Depthtech's proprietary technology: the way to manage developed photoresists. DCCT™ can support customer to know the key source of scums and with Depthtech's technical service, they can manage scums effectively, hence potentially resolve the development low yield problems, especially for more complex PCB manufacturing.",
    slug: "dcct",
    link: "/featured-product",
  },
  {
    id: 2,
    image: product2,
    icon: icon2,
    simage: product2,
    banner: banner2,
    gradient: "rgba(103, 58, 183, 0.3)",
    title: "K/Na High Purity Developer Chemicals",
    description:
      "K/Na High Purity Sodium-Potassium Developer comprises mixtures of sodium carbonate and potassium carbonate with ultra high purity and nearly no impurities which can improve development process.",
    slug: "K-Na",
    link: "/products/K-Na",
    sections: [
      {
        type: "text",
        title: "High Purity Means Cleaner Development Baths for Longer",
        paragraphs: [
          "K/Na High Purity Sodium-Potassium Developer comprises mixtures of sodium carbonate and potassium carbonate with ultra high purity and nearly no impurities which can improve development process.",
          "Choosing our high purity sodium and potassium carbonate can minimize impurities crystals so that these crystals will not stick to the spraying nozzle.",
        ],
      },
    ],
    benefits: [
      "Enhances development yield: Prevents impurities from disrupting the stability of the photoresist colloid.",
      "Excellent development speed: High penetration allowing effective control over the development process.",
    ],
  },
  {
    id: 3,
    image: product3,
    icon: icon3,
    simage: product3,
    banner: banner1,
    gradient: "rgba(103, 58, 183, 0.3)",
    title: "Development Tank Cleaners: DTCU & SM-CL",
    description:
      "DTCU and SM-CL are cleaning wet chemicals which are used to effectively remove sedimented scums on any solid surface of development tank. With support of scum cleaning, the renewed development process yield can be maintained.",
    slug: "vat_cleaner",
    link: "/products/vat_cleaner",
    sections: [
      {
        type: "text",
        title: "Clean Developer Vats: A Must for Better Process Control",
        paragraphs: [
          "DTCU and SM-CL are cleaning wet chemicals which are used to effectively remove sedimented scums on any solid surface of development tank. With support of scum cleaning, the renewed development process yield can be maintained.",
        ],
      },
      // {
      //   type: "text",
      //   title: "Photoresist Dry Film",
      //   paragraphs: [
      //     "After prolonged operation of the development tank in printed circuit board (PCB) production, residual solder mask deposits, defoamer residues, and salt deposits from impurities in sodium carbonate can accumulate inside the tank. This buildup leads to a decline in the quality of the development process.",
      //     "The DTCU cleaning agent is specifically designed for removing buildup in photoresist dry film development vats. Depending on the severity of the buildup, various lengths of wash cycles can be prescribed.",
      //   ],
      // },
      {
        type: "text",
        title: "Dry Film for Inner/Outer Circuit Patterning Process",
        paragraphs: [
          "During printed circuit board pattern development, some developed photoresist materials and alkali salts may deposit and accumulate inside the development tank. These potentially leads to potential risk regarding to the development quality of the substrate.",
          "The DTCU cleaning agent is specifically designed for removing accumulated solids in development tanks with high efficiency.",
        ],
      },
      {
        type: "text",
        title: "Solder Mask",
        paragraphs: [
          "During printed circuit board pattern development, some developed solder mask materials like epoxy and acrylate, inorganic powders, and alkali salts may deposit and accumulate inside the solder mask development tank. These formed scums potentially leads to potential risk regarding to the development quality of the substrate.",
          "The cleaning agent SM-CL is specifically designed for removing any solids in solder mask development tanks with high efficiency.",
        ],
      },
      {
        type: "text",
        title: "How it Works",
        paragraphs: [
          "To address the scums accumulation in development tanks, such as developed solder mask materials and any salt or powder deposits, we have formulated a cleaning chemicals which can effectively break down these scums. The products does not damage the equipment.",
        ],
      },
      {
        type: "image",
        title: "Extreme Pipe Clots",
        images: [vatEffect1, vatEffect2],
      },
      {
        type: "image",
        title: "Surface Plaques",
        images: [vatEffect3, vatEffect4],
      },
    ],
    benefits: [
      "Completely water-soluble",
      "Excellent cleaning capability.",
      "Low operational cost.",
      "Effectively removes dirt at low concentrations.",
      "Non-corrosive to equipment.",
    ],
  },
  {
    id: 4,
    image: product4,
    icon: icon4,
    simage: product4,
    banner: banner1,
    gradient: "rgba(103, 58, 183, 0.3)",
    title: "Silicone Free Foam Controller",
    description:
      "Silicone Free Foam Controller can effectively eliminate foams generated from developed photoresist's compositions during mechanical pumping in development tanks. They are silicone free and will not induce potential defects on substrate surface.",
    slug: "defoamers",
    link: "/products/defoamers",
    sections: [
      {
        type: "text",
        title: "Silicone-Free Foam Controller for Better Colloidal Stability ",
        paragraphs: [
          "Silicone Free Foam Controller can effectively eliminate foams generated from developed photoresist's compositions during mechanical pumping in development tanks. They are silicone free and will not induce potential defects on substrate surface.",
        ],
      },
    ],
    benefits: [
      "Effectively controlling foam with low dosage.",
      "Fast foam breaking capability.",
      "Biodegradable.",
    ],
  },
  {
    id: 5,
    image: simg2,
    icon: icon2,
    simage: simg2,
    banner: banner2,
    gradient: "rgba(103, 58, 183, 0.3)",
    title: "Gas Injected Sprinkler Type Artwork Cleaner",
    description:
      "Newly developed artwork cleaner is much safer than traditional cleaner where the cleaning compositions are injected via non-flammable gas. The new developed cleaner usage per manufacturing line can be decreased significantly, hence CO2 emission can be reduced.",
    slug: "mask-cleaner",
    link: "/products/mask-cleaner",
    sections: [],
    benefits: [
      "No toxic N-hexane inside.",
      "Easy to use. Significantly decrease the cleaner usage while substrate cleaning.",
      "Environmental Friendly.",
    ],
  },
  {
    id: 6,
    image: product6,
    icon: icon2,
    simage: product6,
    banner: banner2,
    gradient: "rgba(103, 58, 183, 0.3)",
    title: "Durable Copper Antioxidant",
    description:
      "This acidic antioxidant is specifically designed for printed circuit boards. It effectively prevents oxidation of the copper surface caused by moisture and hydrogen sulfide in the air, and functions as an acid cleaner. It is suitable for post-water washing processes in various stages, including electroplating, solder mask processes, and post-tin-lead stripping.",
    slug: "anti-oxidant",
    link: "/products/anti-oxidant",
    sections: [
      {
        type: "text",
        title: "Antioxidant Protects Copper from Oxidation",
        paragraphs: [
          "This acidic antioxidant is specifically designed for printed circuit boards. It effectively prevents oxidation of the copper surface caused by moisture and hydrogen sulfide in the air, and functions as an acid cleaner. It is suitable for post-water washing processes in various stages, including electroplating, solder mask processes, and post-tin-lead stripping. When applied in the post-washing treatment of DES (Developing-Etching-Stripping), it can more effectively reduce AOI (Automated Optical Inspection) false positives and improve AOI operational efficiency.",
        ],
      },
    ],
    benefits: [
      "No chromates, oils, or waxes.",
      "Excellent stability.",
      "Effectively protects copper surfaces at low concentrations.",
      "Maintains stability across various temperatures.",
      "Completely water-soluble.",
      "Suitable for use as an antioxidant even after tin-lead stripping.",
    ],
  },
];
export default Services;
