import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import Services from "../../api/Services";
import ServiceSidebar from "./sidebar";
import ServiceSection from "../../components/ServiceSection/ServiceSection";

import Footer from "../../components/footer/Footer";
import logo from "../../images/logo-3.svg";

const ServiceSinglePage = (props) => {
  const { slug } = useParams();

  const serviceDetails = Services.find((item) => item.slug === slug);

  return (
    <Fragment>
      <Navbar Logo={logo} hclass={"wpo-site-header wpo-site-header-s3"} />
      <PageTitle
        pageTitle={serviceDetails.title}
        pagesub={"Service Single"}
        backgroundUrl={serviceDetails.banner}
        backgroundPos="bottom"
        backgroundGradient={serviceDetails.gradient}
      />
      <div className="wpo-service-single-area section-padding">
        <div className="container">
          {/* <div className="row">
            <div className="wpo-service-single-title">
              <h2>{serviceDetails.title}</h2>
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="wpo-service-single-wrap">
                <div className="wpo-service-single-item">
                  <div className="wpo-service-single-main-img">
                    <img src={serviceDetails.simage} alt="eimg" />
                  </div>
                </div>
                {serviceDetails.sections?.map((section, sindex) => (
                  <div
                    className="wpo-service-single-item"
                    key={`section_${sindex}`}
                  >
                    <div className="wpo-service-single-title">
                      <h3>{section.title}</h3>
                    </div>
                    {section.type === "text" ? (
                      <>
                        {section.paragraphs?.map((para, pindex) => (
                          <p key={`s_${sindex}_para_${pindex}`}>{para}</p>
                        ))}
                      </>
                    ) : (
                      <div className="row mt-4">
                        {section.images?.map((imgSrc, pindex) => (
                          <div
                            className="col-md-6 col-sm-6 col-12"
                            key={`s_${sindex}_para_${pindex}`}
                          >
                            <div className="wpo-p-details-img">
                              <img src={imgSrc} alt="" />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}

                {/* <div className="wpo-service-single-item">
                  <div className="row mt-4">
                    <div className="wpo-service-single-title col-12">
                      <h3>Title</h3>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <img src={simg1} alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <img src={simg2} alt="" />
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="wpo-service-single-item list-widget">
                  <div className="wpo-service-single-title">
                    <h3>Key Benefits</h3>
                  </div>
                  {/* <p>
                    Massa volutpat odio facilisis purus sit elementum. Non, sed
                    velit dictum quam. Id risus pharetra est, at rhoncus, nec
                    ullamcorper tincidunt. Id aliquet duis sollicitudin diam.
                  </p> */}
                  <ul>
                    {serviceDetails.benefits?.map((bene, index) => (
                      <li key={`bene_${index}`}>{bene}</li>
                    ))}
                    {/* <li>Non saed velit dictum quam risus pharetra esta.</li>
                    <li>
                      Id risus pharetra est, at rhoncus, nec ullamcorper
                      tincidunt.
                    </li>
                    <li>Hac nibh fermentum nisi, platea condimentum cursus.</li>
                    <li>Massa volutpat odio facilisis purus sit elementum.</li>
                    <li>Elit curabitur amet risus bibendum.</li> */}
                  </ul>
                </div>

                {/* <div className="wpo-service-single-item list-widget">
                  <div className="wpo-service-single-title">
                    <h3>Our Work Process</h3>
                  </div>
                  <ul>
                    <li>Non saed velit dictum quam risus pharetra esta.</li>
                    <li>
                      Id risus pharetra est, at rhoncus, nec ullamcorper
                      tincidunt.
                    </li>
                    <li>Hac nibh fermentum nisi, platea condimentum cursus.</li>
                    <li>Massa volutpat odio facilisis purus sit elementum.</li>
                  </ul>
                </div>
                <div className="wpo-service-single-item">
                  <div className="wpo-service-single-title">
                    <h3>Related Service</h3>
                  </div>
                  <div className="wpo-service-area">
                    <div className="row align-items-center">
                      {Services.slice(0, 2).map((service, index) => (
                        <div className="col-md-6 col-12" key={index}>
                          <div className="service-card">
                            <div className="image">
                              <img src={service.image} alt="" />
                              <span>{index + 1}</span>
                              <div className="line-one"></div>
                              <div className="line-two"></div>
                            </div>
                            <div className="content">
                              <div className="icon">
                                <img src={service.icon} alt="" />
                              </div>
                              <h2>
                                <Link to={`/service-single/${service.slug}`}>
                                  {service.title}
                                </Link>
                              </h2>
                              <p>{service.description}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="wpo-service-single-item">
                  <div className="wpo-service-contact-area">
                    <div className="wpo-contact-title">
                      <h2>Have project in mind? Let's discuss</h2>
                      <p>
                        Get in touch with us to see how we can help you with
                        your project
                      </p>
                    </div>
                    <div className="wpo-contact-form-area">
                      <Contact />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <ServiceSidebar />
          </div>
        </div>
      </div>
      <ServiceSection
        hclass={"wpo-service-section section-padding"}
        title="Other Products"
      />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServiceSinglePage;
