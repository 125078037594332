import React, { useEffect, useState } from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import Compare1 from "../../images/compare/compare_1.png";
import Compare2 from "../../images/compare/compare_2.png";

const About = (props) => {
  const [aboutData, setAboutData] = useState({
    images: {
      image1: "",
      image2: "",
      ceoImage: "",
    },
    title: "",
    subtitle: "",
    paragraphs: [],
    ceoName: "",
    ceoTitle: "",
  });

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    fetch("/about.json")
      .then((response) => response.json())
      .then((data) => {
        setAboutData({
          images: {
            image1: data.image1,
            image2: data.image2,
            ceoImage: data.ceoImage,
          },
          title: data.title,
          subtitle: data.subtitle,
          paragraphs: data.paragraphs || [],
          ceoName: data.ceoName,
          ceoTitle: data.ceoTitle,
        });
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 768px is a common mobile breakpoint
    };

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className={"" + props.hclass} style={{ background: "#f8f8f8" }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-12">
            <div style={{ width: isMobile ? "100%" : "90%" }}>
              <ReactCompareSlider
                itemOne={
                  <ReactCompareSliderImage
                    src={Compare1}
                    // srcSet="..."
                    alt="Image one"
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    src={Compare2}
                    // srcSet="..."
                    alt="Image two"
                  />
                }
              />
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="content">
              <h3>
                DCCT™ - A new technology to pursuit non-scum development process
                environment and high yield
              </h3>
              {aboutData.paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
