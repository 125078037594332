import React, { Component, Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import Logo from '../../images/logo-3.svg';
import AboutPageTitleBg from '../../images/about/about_title_bg.jpg';

import ProductContent from '../../components/products/ProductContent';

const DetailsPage = () => {
  return (
    <Fragment>
      {/* <!-- Content --> */}
      <Navbar hclass={'wpo-site-header wpo-site-header-s3'} Logo={Logo} />
      <PageTitle
        pageTitle={'Product'}
        backgroundUrl={AboutPageTitleBg}
        backgroundPos={'bottom'}
      />
      <ProductContent />

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default DetailsPage;
