import React from "react";
import { Link } from "react-router-dom";

const PageTitle = ({
  pageTitle,
  backgroundUrl = null,
  backgroundPos = "top",
  backgroundGradient = "rgba(48, 65, 104, 0.4)",
}) => {
  return (
    <div
      className="wpo-breadcumb-area"
      style={{
        background: `url(${
          backgroundUrl ? backgroundUrl : "../../images/page-title.jpg"
        }) no-repeat center ${backgroundPos} / cover`,
        before: {
          background: backgroundGradient,
        },
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="wpo-breadcumb-wrap">
              <h2 style={{ marginTop: "100px" }}>{pageTitle}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
