// images
import img1 from "../images/service/service_1.jpg";
import img2 from "../images/service/service_2.jpg";
import img3 from "../images/service/service_3.jpg";
import img4 from "../images/service/service_4.jpg";

/* Single image */
import simg1 from "../images/service-single/img-1.jpg";
import simg2 from "../images/service-single/img-2.jpg";
import simg3 from "../images/service-single/img-3.jpg";
import simg4 from "../images/service-single/img-4.jpg";

/* icon image */
import icon1 from "../images/icon/svg/science.svg";
import icon2 from "../images/icon/svg/innovation.svg";
import icon3 from "../images/icon/svg/quality.svg";
import icon4 from "../images/icon/svg/review.svg";

const WhyUs = [
  {
    id: 1,
    image: img1,
    icon: icon1,
    simage: simg1,
    title: "Scientific Expertise",
    description:
      "Sagittis fringilla a scelerisque sitro consetetur nunc. Urna est eget pellentesque.",
  },
  {
    id: 4,
    image: img2,
    icon: icon2,
    simage: simg2,
    title: "Continuous Innovation",
    description:
      "Sagittis fringilla a scelerisque sitro consetetur nunc. Urna est eget pellentesque.",
  },
  {
    id: 3,
    image: img3,
    icon: icon3,
    simage: simg3,
    title: "Reliable Quality",
    description:
      "Sagittis fringilla a scelerisque sitro consetetur nunc. Urna est eget pellentesque.",
  },
  {
    id: 4,
    image: img4,
    icon: icon4,
    simage: simg4,
    title: "Customer Success",
    description:
      "Sagittis fringilla a scelerisque sitro consetetur nunc. Urna est eget pellentesque.",
  },
];
export default WhyUs;
