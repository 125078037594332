import React from "react";
import { Link } from "react-router-dom";

const Hero = ({ hclass }) => {
  return (
    <section className={"" + hclass}>
      <div className="wraper">
        <div className="content">
          <h3>
            Leader in Wet Process Chemicals <br /> for PCB Manufacturing
          </h3>
          <p>
            For 25 years, Depthtech has been innovating process chemicals in the
            photo image transfer process of PCB manufacturing.
          </p>
          <p>
            Depthtech pioneered to propose the concept: "one should keep
            development process environment clean so that the development
            process yield is maintained" and invented first process washing
            methodology with wet chemicals to support customers.
          </p>

          <p>
            Now, back to the fundamentals, we are revolutionizing the concept to
            pursue a scum-free development process environment. With our
            proprietary developer colloid control technology (DCCT™) we can
            investigate and quantify the properties of developed photoresists
            and innovate ways to "control" and "manage" them. Finally, the
            concept of a scum-free development process became feasible,
            potentially decreasing chemical usage and the water used for
            cleaning, aligning with the modern ESG spirit.
          </p>
          <div className="hero-btn">
            <Link
              to="/featured-product"
              className="theme-btn"
              style={{ marginTop: "30px" }}
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
