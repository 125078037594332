import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import Compare1 from "../../images/compare/compare_1.png";
import Compare2 from "../../images/compare/compare_2.png";

import teamImage from "../../images/about/team.jpg";
import mapImage from "../../images/about/china_map_dot.png";
import thumbBanner2 from "../../images/about/taoyuan.png";
import thumbBanner3 from "../../images/about/kunshan_zhou.jpg";
import thumbBanner4 from "../../images/about/shenzhen.jpg";
import teamFactImage from "../../images/about/team_factory.jpg";
const AboutCompany = (props) => {
  return (
    <section className={"" + props.hclass} style={{ background: "#f8f8f8" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="exprience-wrap">
              <h2>Two Decades of Expertise</h2>
              <p>
                For 25 years, Depthtech has been innovating process chemicals in
                the photo image transfer step of PCB manufacturing. Depthtech
                was one of the pioneers promoting the use of development tank
                cleaners. In its second decade, Depthtech adjusted the
                composition and the industrial packaging of artwork cleaners to
                protect clients from the fire hazard poised by alternative
                products. Now, Depthtech is revolutionizing the way top PCB
                manufacturers think about how developed photoresist form scums
                in the development tank and contaminate downstream processes. In
                this third decade, Depthtech is on a mission to solve the
                problem of scums in the photoresist development tank. Our
                expertise has grown via important additions of individuals to
                our team including experts in colloidal science, PCB
                manufacturing, quality assurance, sales and business operations.
                We are tremendously excited to continue pioneering innovation in
                PCB manufacturing and warmly welcome new potential clients to
                experience the difference Depthtech's chemistry expertise can
                make.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="white-bg"
        style={{ marginTop: "50px", marginBottom: "50px" }}
      >
        <div className="container white-bg">
          <div className="row align-items-center lr-banner">
            <div className="col-lg-6 col-12 f-left">
              <img src={mapImage} alt="" />
            </div>
            <div className="col-lg-6 col-12 about-page">
              <div className="wpo-service-single-item list-widget">
                <div className="wpo-service-single-title">
                  <h3>At a Glance</h3>
                </div>

                <ul>
                  <li>
                    Established since <b>2001</b>
                  </li>
                  <li>
                    <b>$10M </b>USD Annual Revenue
                  </li>
                  <li>
                    <b>3</b> Factories
                  </li>
                  <li>
                    <b>3</b> Offices
                  </li>
                  <li>
                    <b>1</b> Research Center
                  </li>
                  <li>
                    <b>1</b> Warehouse
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 hover-card"
            style={{
              background: `transparent`,
            }}
          >
            <div className="card-paragrap-nopad">
              <h2>At the Heart of Innovation</h2>
              <p>
                Depthtech is located at the heart of the world’s leading
                industrial areas for PCB manufacturing. Established at the turn
                of the century in Taiwan, our operations have grown
                internationally.
              </p>
            </div>
          </div>
          <div
            className="col-lg-6 hover-card"
            style={{
              background: `url(${thumbBanner2}) no-repeat center bottom / cover`,
            }}
          >
            <p className="card-title text-white">Taoyuan, Taiwan</p>
            <div className="card-gradient"></div>
            <div className="card-overlay">
              <div className="card-subtitle">
                400m<sup>2</sup> <br />
                Production, Research, Sales
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 hover-card"
            style={{
              background: `url(${thumbBanner3}) no-repeat center top / cover`,
            }}
          >
            <p className="card-title text-white">Kunshan, China</p>
            <div className="card-gradient"></div>
            <div className="card-overlay">
              <div className="card-subtitle">
                500m<sup>2</sup> <br />
                Production, Warehousing, Sales
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 hover-card"
            style={{
              background: `url(${thumbBanner4}) no-repeat center top / cover`,
            }}
          >
            <p className="card-title text-white">Shenzhen, China</p>
            <div className="card-gradient"></div>
            <div className="card-overlay">
              <div className="card-subtitle">
                300m<sup>2</sup> <br />
                Production, Sales
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 hover-card"
            style={{
              background: `transparent`,
            }}
          >
            <p className="card-paragraph quote">
              With strategic locations dedicated to the largest and most
              advanced PCB markets, our locations have access to leading
              innovation and global seaports to do business worldwide.
            </p>
          </div>
        </div>
      </div>
      <div className="about-cta-wrapper">
        <div className="container">
          <div className="row align-items-center lr-banner">
            <div className="col-lg-6 col-12">
              <div className="wpo-service-single-item">
                <div className="wpo-service-single-title">
                  <h2>Committed, Experienced and Passionate</h2>
                </div>

                <p>
                  Depthtech’s team is made up of experienced team members who
                  has been with the company since day one and passionate
                  individuals who has joined us believing in our mission to
                  revolutionize the PCB manufacturing industry. Our leaders and
                  scientists come from top universities and have decades of
                  experience in the industry. Our sales persons are passionate
                  and friendly individuals who provide top quality service to
                  customers and our workforce are committed and detailed
                  oriented people who tirelessly uphold manufacturing standards
                  you trust.
                </p>
                <p>
                  Depthtech is a brand of reliable chemistry and people that PCB
                  manufacturers can depend on to meet their next generation
                  demands. Talk with our experts today and discover how
                  Depthtech's products can enhance your photo image transfer
                  development process!
                </p>

                <div className="hero-btn" style={{ marginTop: "50px" }}>
                  <Link to="/contact" className="theme-btn">
                    Connect with Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 f-right">
              <img src={teamFactImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCompany;
